<template>
    <div class="communityDetailBox" v-myTitle :data-title="article.name+'-'+'我要发帖'+'-企胖胖'">
        <Search />
        <div class="communityDetail">
            <div class="content">
                <div class="nav">
                    <div class="fh" @click="toRetu"> <img src="../../assets/images/community/fh (11).png" alt=""><span>返回</span></div>

                    <div class="fat"><span @click="goSend">[我要发帖]</span><span @click="goInvitation">我的</span></div>

                </div>
                <div class="article">
                    <div class="title">
                        <p>{{article.name}}</p>
                        <p class="info">
                            <span @click="goHim(article.uid)">发帖人：{{article.sendUserName||"平台"}}</span>
                            <span>{{article.createTime}}</span>
                            <span>{{article.classText}}</span>
                        </p>
                    </div>
                    <p class="operation">
                        <span @click="focusOn(article.uid)">关注作者</span>
                        <span @click="collect">收藏帖子</span>
                    </p>
                    <p class="ql-editor text" v-html="article.content">
                    </p>
                    <!-- <img style="width:370px; height:370px" :src="$util.host +article.cover" alt="" /> -->
                    <div class="icon">
                        <div @click="setLike">
                            <img src="../../assets/images/community/zd.png" alt="" />
                            <p>{{article.fabulousNum}}人点赞</p>
                        </div>
                        <div>
                            <img src="../../assets/images/community/pl.png" alt="" />
                            <p>我要评论</p>
                        </div>
                    </div>
                </div>
                <div class="comment">
                    <p class="title">评论</p>
                    <div class="list">
                        <!-- 主贴 1级 -->
                        <div class="oneLayer" v-for="item in list" :key="item.id">
                            <div class="oneInfo">
                                <p>{{item.userName}}</p>
                                <p>{{item.createTime}}</p>
                            </div>
                            <div class="oneContent">
                                <span class="text">{{item.txt}}
                                    <at class="lorem"></at>
                                </span>
                                <div class="imgs" v-if="item.images != ''">
                                    <!-- 图片地址 -->
                                    <img :src="$util.host +img" v-for="(img,index) in item.images.split(',')" @click="outerV(img)" :key="index" alt="">
                                </div>
                                <div class="interaction">
                                    <span @click="setCommenLike(item.id)">{{item.fabulousNum}}点赞</span><span></span><span @click="replyOpen(item)">回复</span>
                                </div>
                                <div class="seeALLThree" v-if="item.next.length >0 & !showReply" @click="showReply = !showReply">
                                    共{{item.next.length}}条回复
                                    <img src="../../assets/images/community/xljt.png" alt="" />
                                </div>
                                <div v-if="showReply">
                                    <div class="threeLayer" v-for="val in item.next" :key="val.id">
                                        <div class="oneInfo">
                                            <p>{{val.userName}}</p>
                                            <p>{{val.createTime}}</p>
                                        </div>
                                        <div class="threeContent">
                                            <span class="text">{{val.txt}}
                                                <at class="lorem"></at>
                                            </span>
                                            <div class="imgs" v-if="val.images != ''">
                                                <img :src="$util.host +img" v-for="(img,index) in val.images.split(',')" :key="index" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 二级评论 -->
                        <div class="twoLayer" v-for="item in evaluates" :key="item.id">
                            <div class="oneInfo">
                                <p>{{item.userName}}</p>
                                <p>{{item.createTime}}</p>
                            </div>
                            <div class="oneContent">
                                <span class="text">{{item.txt}}
                                    <at class="lorem"></at>
                                </span>
                                <div class="imgs" v-if="item.images != ''">
                                    <img :src="$util.host +img" v-for="(img,index) in item.images.split(',')" @click="outerV(img)" :key="index" alt="">
                                </div>
                                <div class="interaction">
                                    <span @click="setCommenLike(item.id)">{{item.fabulousNum}}点赞</span><span></span><span @click="replyOpen(item)">回复</span>
                                </div>
                                <div class="seeALLThree" v-if="item.next.length >0 & !showReply" @click="showReply = !showReply">
                                    共{{item.next.length}}条回复
                                    <img src="../../assets/images/community/xljt.png" alt="" />
                                </div>
                                <div v-if="showReply">
                                    <div class="threeLayer" v-for="val in item.next" :key="val.id">
                                        <div class="oneInfo">
                                            <p>{{val.userName}}</p>
                                            <p>{{val.createTime}}</p>
                                        </div>
                                        <div class="threeContent">
                                            <span class="text">{{val.txt}}
                                                <at class="lorem"></at>
                                            </span>
                                            <div class="imgs" v-if="val.images != ''">
                                                <img :src="$util.host +img" v-for="(img,index) in val.images.split(',')" :key="index" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="seeALLTwo" @click="twoLayer = 5" v-if="twoLayer != 5">
                            共5条回复
                            <img src="../../assets/images/community/xljt.png" alt="" />
                        </div> -->
                    </div>
                </div>
                <div class="commentInput">
                    <p class="title">我要评论</p>
                    <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" placeholder="" v-model="form.txt" resize="none">
                    </el-input>
                    <div class="publicity">
                        <el-upload :limit="5" :action="action" list-type="picture-card" :on-success="beforeLoad" :on-remove="removeImg">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </div>
                    <div class="fot-btn">
                        <div @click="comment">评论</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 回复： -->
        <el-dialog :visible.sync="replyShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div>回复: <span style="color:#e40013">{{itemInfo.userName}}</span> </div>
            <div style="font-size:18px;margin:10px 0;">{{itemInfo.txt}}</div>
            <div class="commentInput">
                <!-- <p class="title">我要评论</p> -->
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" placeholder="" v-model="replyForm.txt" resize="none">
                </el-input>
                <div class="publicity">
                    <el-upload :limit="5" :action="action" list-type="picture-card" :on-success="replyBeforeLoad" :on-remove="replyRemoveImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="fot-btn">
                    <div @click="reply">回复</div>
                </div>
            </div>
        </el-dialog>
        <!-- 图片放大 -->
        <el-dialog :visible.sync="outerVisible">
            <img style="width:100%;height:100%;" :src="$util.host +imgSrc" alt="">
        </el-dialog>

        <Footer />
    </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import Search from "../../components/forumSearch.vue";
export default {
    components: { Footer, Search },
    data() {
        return {
            outerVisible: false,
            // innerVisible: false,
            article: {}, //主贴内容
            list: [], //作者评论列表
            evaluates: [],//二级评论
            textarea: "", //评论内容
            action: this.$util.upload,
            form: { //评论
                txt: '',
                images: '',
                articleId: this.$route.query.id,
                fid: 0,
                type: 1,
            },
            replyShow: false, //回复弹框
            replyForm: { //回复
                txt: '',
                images: '',
                articleId: this.$route.query.id,
                fid: null,
                type: 1,
            },
            itemInfo: {}, //回显内容
            twoLayer: 3,
            showReply: false,
            articleId: this.$route.query.id,
            imgSrc: '',//图片地址
        };
    },
    mounted() {
        window.scrollTo(0, 0);
        this.allData()
    },
    methods: {
        outerV(val) {
            this.imgSrc = val
            this.outerVisible = true
        },
        //返回
        toRetu() {
            this.$router.go(-1)
        },
        // 获取所有评论数据
        allData() {
            this.$util.post('/forum/article/details', {
                articleId: this.articleId
            }).then(res => {
                res.data.article.content = res.data.article.content.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
                    return match.replace("../../../", this.$util.host + '/')
                });
                this.article = res.data.article
                let list = []
                let evaluates = []
                res.data.evaluates.forEach(item => {
                    if (item.uid == res.data.article.uid) {
                        list.push(item)
                    } else {
                        evaluates.push(item)
                    }
                })
                this.list = list
                console.log(list);

                this.evaluates = evaluates

                this.getClass()
            })
        },
        getClass() {
            this.$util.post('/shop/allClassify').then(res => {
                res.data.forEach(item => {
                    if (this.article.classifyId == item.id) {
                        this.$set(this.article, 'classText', item.name)

                    }
                })
            })
        },
        // 点赞
        setLike() {
            this.$util.post('/forum/action/fabulous/article', {
                articleId: this.article.id
            }).then(res => {
                if (res.code == 200) {
                    this.allData()
                } else {
                    this.$message.error("暂未登录,请先登录");
                }
            })
        },
        // 评论点赞
        setCommenLike(id) {
            this.$util.post('/forum/action/fabulous/evaluate', {
                evaluateId: id
            }).then(res => {
                if (res.code == 200) {
                    this.allData()
                } else {
                    this.$message.error("暂未登录,请先登录");
                }
            })
        },
        // 上传
        beforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form.images = imgs.join(',')
        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form.images = imgs.join(',')
        },
        // 评论
        comment() {
            if (this.form.txt == '') {
                return this.$message.error('评论内容不能为空');
            }
            this.$util.post('/forum/action/comment', this.form).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.allData()
                    this.form = { //评论
                        txt: '',
                        images: '',
                        articleId: this.$route.query.id,
                        fid: 0,
                        type: 1,
                    }
                    location.reload()
                } else {
                    this.$message.error("暂未登录,请先登录");
                }

            })
        },
        // 回复
        replyOpen(item) {
            this.replyShow = true
            this.replyForm.fid = item.id
            this.itemInfo = item

        },
        // 上传
        replyBeforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.replyForm.images = imgs.join(',')
        },
        replyRemoveImg(file, fileList) {
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.replyForm.images = imgs.join(',')
        },
        reply() {
            if (this.replyForm.txt == '') {
                return this.$message.error('回复内容不能为空');
            }
            this.$util.post('/forum/action/comment', this.replyForm).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.allData()
                    this.replyShow = false
                } else {
                    this.$message.error(res.message);
                }

            })
        },
        handleClose(done) {
            this.replyShow = false
        },
        // 收藏
        collect() {
            this.$util.post('/forum/collection', {
                articleId: this.articleId
            }).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.$message.success("已收藏")
                } else if (res.code) {
                    this.$message.error("暂未登录,请先登录");
                }

            })
        },
        // 关注
        focusOn(id) {
            this.$util.post('/user/fabulous', {
                uid: id
            }).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.$message.success("已关注")
                } else if (res.code == 400) {
                    this.$message.error("暂未登录,请先登录");
                }

            })
        },
        goHim(id) {
            this.$router.push({ path: '/himCreate', query: { id } })
        },
        goSend() {
            if (sessionStorage.getItem("token")) {
                this.$router.push({ path: '/post' })
            } else {
                this.$message.error("暂未登录,请先登录");
            }

        },
        goInvitation() {
            this.$router.push({ path: '/invitation' })
        }
    },
};
</script>

<style lang="scss" scoped>
.communityDetailBox {
    .threeLayer {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        .oneInfo {
            p:nth-child(1) {
                margin-bottom: 10px;
                font-size: 18px;
                color: #e40012;
            }
            p:nth-child(2) {
                color: #999999;
                font-size: 14px;
            }
        }
        .threeContent {
            width: 709px;
            .text {
                font-size: 14px;
                color: #222222;
                line-height: 26px;
            }
            .interaction {
                display: flex;
                justify-content: flex-end;
                font-size: 16px;
                margin-top: 20px;
                color: #999999;
                span {
                    margin-left: 30px;
                    cursor: pointer;
                }
                span:nth-child(2) {
                    display: block;
                    width: 1px;
                    height: 20px;
                    background: #999999;
                }
            }
        }
    }
    .seeALLTwo {
        padding: 30px;
        padding-bottom: 50px;
        padding-top: 0;
        background: rgba(113, 113, 113, 0.03);
        color: #e40012;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
    }
    .communityDetail {
        width: 100%;
        background: #f6f6f6;

        .content {
            width: 1200px;
            margin: auto;
            padding-top: 30px;
            .commentInput {
                padding: 30px;
                background: #ffffff;
                margin-top: 30px;
                margin-bottom: 20px;

                .addImg {
                    font-size: 18px;
                    color: #e40012;
                    padding-left: 30px;
                    cursor: pointer;
                }

                .title {
                    color: #222222;
                    font-size: 22px;
                    font-weight: bold;
                }
            }

            .comment {
                padding: 30px;
                margin-top: 30px;
                background: #ffffff;

                .twoLayer {
                    padding: 30px;
                    background: rgba(113, 113, 113, 0.03);
                    display: flex;
                    justify-content: space-between;

                    .oneInfo {
                        p:nth-child(1) {
                            margin-bottom: 10px;
                            font-size: 18px;
                            color: #222222;
                        }
                        p:nth-child(2) {
                            color: #999999;
                            font-size: 14px;
                        }
                    }
                    .oneContent {
                        width: 897px;
                        .text {
                            font-size: 14px;
                            color: #222222;
                            line-height: 26px;
                        }
                        .seeALLThree {
                            cursor: pointer;
                            color: #e40012;
                        }
                        .interaction {
                            display: flex;
                            justify-content: flex-end;
                            font-size: 16px;
                            margin-top: 20px;

                            color: #999999;
                            span {
                                margin-left: 30px;
                                cursor: pointer;
                            }
                            span:nth-child(2) {
                                display: block;
                                width: 1px;
                                height: 20px;
                                background: #999999;
                            }
                        }
                    }
                }
                .oneLayer {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 50px;
                    .oneInfo {
                        p:nth-child(1) {
                            margin-bottom: 10px;
                            font-size: 18px;
                            color: #e40012;
                        }
                        p:nth-child(2) {
                            color: #999999;
                            font-size: 14px;
                        }
                    }
                    .oneContent {
                        width: 911px;
                        .text {
                            font-size: 14px;
                            color: #222222;
                            line-height: 26px;
                        }
                        .interaction {
                            display: flex;
                            justify-content: flex-end;
                            font-size: 16px;
                            margin-top: 20px;
                            color: #999999;
                            span {
                                margin-left: 30px;
                                cursor: pointer;
                            }
                            span:nth-child(2) {
                                display: block;
                                width: 1px;
                                height: 20px;
                                background: #999999;
                            }
                        }
                    }
                }
                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #222222;
                    padding-bottom: 30px;
                }
            }

            .article {
                padding: 30px;
                margin-top: 30px;
                background: #ffffff;
                .info {
                    display: flex;
                    height: 21px;
                    line-height: 21px;
                }
                .text {
                    // text-align: center;
                    color: #666666;
                    font-size: 16px;
                    // line-height: 26px;
                    text-indent: 30px;
                    overflow: auto;
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: space-around;
                    /deep/img {
                        max-width: 100%;
                        // height: 300px;
                    }
                }
                .operation {
                    display: flex;
                    font-size: 16px;
                    padding: 30px 0;
                    color: #2ea7e0;
                    span {
                        line-height: 36px;
                        text-align: center;
                        width: 92px;
                        height: 36px;
                        background: rgba(46, 167, 224, 0.1);
                        border-radius: 0px 0px 0px 0px;
                        opacity: 1;
                        cursor: pointer;
                    }
                    justify-content: flex-start;
                    span:nth-child(1) {
                        margin-right: 128px;
                    }
                }
                .icon {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    padding-top: 100px;
                    div {
                        cursor: pointer;
                        text-align: center;
                        margin-left: 40px;
                        color: #666666;
                        font-size: 16px;
                    }
                }
                .title {
                    p {
                        display: flex;
                        justify-content: center;
                    }
                    p:nth-child(1) {
                        font-size: 26px;
                        color: #222222;
                        font-weight: bold;
                        padding-bottom: 20px;
                    }
                    p:nth-child(2) {
                        span:nth-child(2) {
                            margin: 0 74px;
                        }
                    }

                    width: 100%;
                    border-bottom: 1px solid #eeeeee;
                }
            }
            .nav {
                font-size: 16px;
                color: #222222;
                padding: 20px 30px;
                background: #ffffff;
                display: flex;
                justify-content: space-between;
                img {
                    width: 22.15px;
                    height: 22.15px;
                }
                span {
                    cursor: pointer;
                    margin-left: 45px;
                }
                .fh {
                    display: flex;
                    span {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
.publicity {
    padding: 30px 0;
    display: flex;
    align-items: center;
}
.fot-btn {
    display: flex;
    justify-content: flex-end;
    div {
        cursor: pointer;
        font-size: 18px;
        background: #e40012;
        color: #fff;
        padding: 10px 60px;
    }
}
.imgs {
    display: flex;
    align-items: center;
    margin: 10px 0;
    img {
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
}
</style>
